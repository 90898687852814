import { imageLoader } from 'next-image-loader/build/image-loader'

// write self-define a custom loader
// (resolverProps: { src: string; width: number; quality?: number }) => string
imageLoader.loader = ({ src, width, quality }) => {
  //* for dev purpose, also a flag to turn on/off image optimized
  return `${
    process.env.ENV_ARG === 'dev' || !process.env.OPTIMIZE_IMAGE
      ? `${src}?`
      : `https://next-image-loader-serverless-awvyuy6x5a-as.a.run.app?url=${encodeURIComponent(src)}&`
  }w=${Math.min(width, 1080)}&q=${quality || 75}`
}
